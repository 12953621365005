import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByKey',
  standalone: true,
})
export class FilterByKeyPipe implements PipeTransform {
  public transform(
    options: any[],
    searchString: string,
    reset: boolean,
    field?: string
  ): any[] {
    if (reset || !searchString) return options;
    if (field) {
      return options.filter(option =>
        option[field]?.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return options.filter(option =>
      option?.toLowerCase().includes(searchString.toLowerCase())
    );
  }
}
