import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { catchError, finalize, throwError } from 'rxjs';
import { UserApiService } from 'src/app/shared/api/user/user-api.service';
import { emailValidator } from 'src/app/shared/validators/email.validator';

import { InputComponent } from '../../controls/input/input.component';
import { AlertStatus } from '../../models/enums/alert-status.enum';
import { AlertService } from '../../services/alert.service';
import {
  addFormValidators,
  FormValidators,
  removeFormValidators,
} from '../../utility/add-form-validators';
import { BtnDirective } from '../btn/btn.directive';
import { CloseButtonComponent } from '../close-button/close-button.component';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BtnDirective,
    CloseButtonComponent,
    InputComponent,
    ReactiveFormsModule,
  ],
})
export class PasswordRecoveryComponent implements OnInit {
  public email: string;

  public isForgot = true;

  public forgotPassForm: UntypedFormGroup;

  public isGettingLink = false;

  private readonly forgotPassFormValidators: FormValidators = {
    email: [Validators.required, emailValidator()],
  };

  constructor(
    public popupRef: DialogRef,
    private alertService: AlertService,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private userApiService: UserApiService
  ) {}

  public ngOnInit(): void {
    this.formInit();
  }

  public getLink(): void {
    addFormValidators(this.forgotPassForm, this.forgotPassFormValidators);
    this.forgotPassForm.markAllAsTouched();

    if (this.forgotPassForm.valid) {
      this.isGettingLink = true;
      this.email = this.forgotPassForm.getRawValue()['email'].trim();

      this.userApiService
        .recoverPassword$(this.email.toLowerCase())
        .pipe(
          catchError(error => {
            if (error.error) {
              this.alertService.show({
                title: error.error.title,
                message: error.error.message,
                status: AlertStatus.Error,
              });
            }
            return throwError(() => error);
          }),
          finalize(() => {
            this.isGettingLink = false;
            this.cdr.markForCheck();
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => {
          this.isForgot = false;
          this.cdr.markForCheck();
        });
    }
  }

  public closeDialog(): void {
    this.popupRef.close();
  }

  private formInit(): void {
    this.forgotPassForm = this.formBuilder.group({
      email: [null],
    });
    this.forgotPassForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        removeFormValidators(this.forgotPassForm);
      });
  }
}
