import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ICountryDTO } from '../models/interfaces/country.interface';

export function countryValidator(countriesArray: ICountryDTO[]): ValidatorFn {
  const notSelectedValues = [
    'Nicht ausgewählt',
    'Not selected',
    'Не выбрано',
    '',
  ];
  return (control: AbstractControl): ValidationErrors | null => {
    const countryInArray = countriesArray.some(country =>
      Object.values(country.localName).includes(control.value)
    );

    return countryInArray || notSelectedValues.includes(control.value)
      ? null
      : { include: true };
  };
}
