import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { IUserCheckedEmail } from '../../../store/user/user-state.interface';
import { IEditUserResponse } from '../../models/interfaces/auth/edit-user-response';
import { IChangePassword } from '../../models/interfaces/change-password.interface';
import { IEditUser } from '../../models/interfaces/edit-user.interface';
import { ILoginUser } from '../../models/interfaces/login-user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpClient) {}

  public getUser$(): Observable<ILoginUser> {
    return this.http.get<ILoginUser>(`${environment.apiUrl}/web/user`);
  }

  public editUser$(
    editUser: IEditUser,
    userId: string
  ): Observable<IEditUserResponse> {
    return this.http.patch<IEditUserResponse>(
      `${environment.apiUrl}/web/users/${userId}`,
      editUser
    );
  }

  public recoverPassword$(email: string): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/web/users/password/recover`,
      {
        email,
      }
    );
  }

  public changePassword$(changePassword: IChangePassword): Observable<void> {
    return this.http.patch<void>(
      `${environment.apiUrl}/web/users/password`,
      changePassword
    );
  }

  public checkEmail$(email: string): Observable<IUserCheckedEmail> {
    return this.http.post<IUserCheckedEmail>(
      `${environment.apiUrl}/web/users/check`,
      {
        email,
      }
    );
  }
}
