import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function lengthValidator(
  minLength: number,
  maxLength: number
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valueLength = control.value?.length || 0;

    return valueLength >= minLength && valueLength <= maxLength
      ? null
      : { length: true };
  };
}
