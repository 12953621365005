<app-close-button class="modal" (click)="close()"></app-close-button>

<span i18n class="t-heading-3 t-bold">Создать аккаунт</span>

<p class="t-subtitle have-account">
  <ng-container i18n>У вас уже есть аккаунт VOKA?</ng-container>
  &nbsp;<span i18n class="have-account_link" (click)="openLogin()">Войти</span>
</p>

<form class="form" [formGroup]="signUpForm" (ngSubmit)="signUp()">
  <div class="half-width">
    <app-input
      formControlName="firstName"
      class="form-field"
      i18n-label
      label="Имя"
      [maxlength]="inputMaxLength"
      [errorsMap]="{
        required: 'first-name',
      }"
      [markedAsRequired]="true">
    </app-input>
    <app-input
      formControlName="lastName"
      class="form-field"
      i18n-label
      label="Фамилия"
      [maxlength]="inputMaxLength"
      [errorsMap]="{
        required: 'last-name',
      }"
      [markedAsRequired]="true">
    </app-input>
  </div>

  <app-input
    class="form-field"
    formControlName="email"
    type="email"
    i18n-label
    label="Электронная почта"
    [errorsMap]="{
      required: 'email',
      email: 'email',
    }"
    [markedAsRequired]="true">
  </app-input>
  <app-input
    class="form-field"
    formControlName="password"
    type="password"
    i18n-label
    label="Пароль"
    [maxlength]="passwordMaxLength"
    [errorsMap]="{
      required: 'password',
      length: 'password',
    }"
    [markedAsRequired]="true">
  </app-input>
  <app-input
    class="form-field"
    formControlName="passwordConfirm"
    type="password"
    i18n-label
    label="Повторите пароль"
    [maxlength]="passwordMaxLength"
    [errorsMap]="{
      match: 'confirm-password',
      required: 'password',
      length: 'password',
    }"
    [markedAsRequired]="true">
  </app-input>

  <div class="half-width">
    <app-autocomplete
      formControlName="country"
      labelField="name"
      class="form-field"
      i18n-label
      label="Страна"
      [errorsMap]="{
        required: 'country',
        include: 'country',
      }"
      [options]="countriesService.localizedCountriesList">
      <ng-template #item let-option>
        <div class="country">
          @if (option.image; as flag) {
            <div class="flag-icon-wrapper">
              <img alt="flag" class="flag-icon" [src]="flag" />
            </div>
          }

          {{ option.name }}
        </div>
      </ng-template>
    </app-autocomplete>

    <app-dropdown
      formControlName="participateAs"
      labelField="label"
      class="form-field"
      valueField="value"
      i18n-label
      label="Деятельность"
      [errorsMap]="{
        required: 'role',
      }"
      [options]="roles"
      [markedAsRequired]="true"
      (lastOptionChosen)="optionSelect($event)">
      <ng-template #item let-option>
        {{ option['label'] }}
      </ng-template>
    </app-dropdown>
  </div>
  @if (participateAsSelected) {
    <app-input
      class="form-field"
      formControlName="participateAsDescription"
      i18n-label
      label="Укажите ваш вариант"
      [maxlength]="100">
    </app-input>
  }

  <app-checkbox
    class="policy"
    formControlName="policy"
    [errorsMap]="{
      required: 'checkbox',
    }">
    <ng-container i18n>Я соглашаюсь с</ng-container>&nbsp;<a
      target="_blank"
      class="link"
      i18n
      [routerLink]="routingMap.termsOfUse"
      >Условиями использования</a
    >&nbsp;<ng-container i18n>и</ng-container>&nbsp;<a
      target="_blank"
      class="link"
      i18n
      [routerLink]="routingMap.privacy"
      >Политикой приватности</a
    >
  </app-checkbox>

  <button
    appButton
    id="button-sign-up"
    class="sign-up t-button-1"
    type="submit"
    i18n
    [loading]="isSigningUp">
    Создать аккаунт
  </button>
</form>
