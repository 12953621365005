<div class="field-checkbox">
  <input
    class="checkbox"
    type="checkbox"
    id="checkbox"
    [formControl]="formControl" />
  <label class="label" [class.error]="errorState" for="checkbox">
    <span class="text-container"><ng-content></ng-content></span>
  </label>
</div>

<span class="notification">{{ error }}</span>
