<app-close-button class="modal" (click)="close()"></app-close-button>

<img
  [appColorThemeImage]="{
    light: 'assets/img/mail.png',
    dark: 'assets/img/mail-dark.png',
  }"
  class="image"
  alt="" />

<span i18n class="t-heading-5 t-bold t-center">
  Регистрация почти завершена. <br />Проверьте вашу почту</span
>

<p class="t-subtitle text" i18n>
  Мы отправили письмо для активации аккаунта на
  <span class="accent-color">{{ dialogData?.email }}</span
  >. Пожалуйста, перейдите по ссылке в письме, чтобы завершить регистрацию
</p>

<div class="info">
  <svg-icon class="info__icon" key="info"></svg-icon>
  <p class="t-medium info__text">
    <ng-container i18n>
      Потеряли письмо? Проверьте папку со спамом или
    </ng-container>
    <br />
    @if (!isTimerActive()) {
      <span i18n class="link" (click)="resendLetter()">Отправьте повторно</span>
    } @else {
      <span class="link_disabled">
        <ng-container i18n>Отправьте повторно через</ng-container>&nbsp; 00:{{
          remainingTime()
        }}
      </span>
    }
  </p>
</div>

<button i18n appButton (click)="checkEmail()">Я активировал(а) email</button>

<span class="t-subtitle have-account" (click)="openLogin()">
  <ng-container i18n>У вас уже есть аккаунт VOKA?</ng-container>
  &nbsp;<span i18n class="link">Войти</span></span
>
