import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BtnDirective } from '@app/shared/components/btn/btn.directive';
import { CloseButtonComponent } from '@app/shared/components/close-button/close-button.component';
import { PasswordRecoveryComponent } from '@app/shared/components/password-recovery/password-recovery.component';
import { SignUpComponent } from '@app/shared/components/sign-up/sign-up.component';
import { InputComponent } from '@app/shared/controls/input/input.component';
import {
  FormValidators,
  addFormValidators,
  removeFormValidators,
} from '@app/shared/utility/add-form-validators';
import { emailValidator } from '@app/shared/validators/email.validator';
import { lengthValidator } from '@app/shared/validators/length.validator';
import { Store } from '@ngrx/store';
import { getAllCategoriesForce } from '@store/categories/categories.actions';
import { loginUser } from '@store/user/user.actions';
import {
  selectIsUserLoggingIn,
  selectUserInfo,
} from '@store/user/user.selectors';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    BtnDirective,
    CloseButtonComponent,
    InputComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;

  public userLoggingIn = false;

  public maxPasswordLength = 30;

  private userLoggingIn$ = this.store.select(selectIsUserLoggingIn);

  private readonly signInFormValidators: FormValidators = {
    email: [Validators.required, emailValidator()],
    password: [Validators.required, lengthValidator(6, this.maxPasswordLength)],
  };

  constructor(
    @Inject(DIALOG_DATA) private dialogData: { email: string },
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private dialog: Dialog,
    private dialogRef: DialogRef<any, LoginComponent>,
    private fb: FormBuilder,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.initLoginForm();
    this.setUserLoggingInSpinner();
  }

  public login(): void {
    this.loginForm.markAllAsTouched();
    addFormValidators(this.loginForm, this.signInFormValidators);

    if (this.loginForm.valid) {
      const formValue = this.loginForm.getRawValue();

      this.store
        .select(selectUserInfo)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(user => {
          if (user) {
            this.store.dispatch(getAllCategoriesForce());
            this.closeDialog();
          }
        });

      this.store.dispatch(
        loginUser({
          loginData: {
            ...formValue,
            email: (formValue.email as string).trim().toLowerCase(),
          },
        })
      );
    }
  }

  public forgotPassword(): void {
    this.dialog.open(PasswordRecoveryComponent);
    this.dialogRef.close();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public openSignUp(): void {
    this.dialog.open(SignUpComponent);
    this.closeDialog();
  }

  private initLoginForm(): void {
    this.loginForm = this.fb.group({
      email: [this.dialogData?.email, [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.loginForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        removeFormValidators(this.loginForm);
      });
  }

  private setUserLoggingInSpinner(): void {
    this.userLoggingIn$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(uerLogging => {
        this.userLoggingIn = uerLogging;
        this.cdRef.markForCheck();
      });
  }
}
