export const countries = [
  {
    code: 'AD',
    defaultName: 'Andorra',
    localName: { de: 'Andorra', en: 'Andorra', ru: 'Андорра' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg',
  },
  {
    code: 'AE',
    defaultName: 'United Arab Emirates',
    localName: {
      de: 'Vereinigte Arabische Emirate',
      en: 'United Arab Emirates',
      ru: 'ОАЭ',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg',
  },
  {
    code: 'AF',
    defaultName: 'Afghanistan',
    localName: {
      de: 'Afghanistan',
      en: 'Afghanistan',
      ru: 'Афганистан',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg',
  },
  {
    code: 'AG',
    defaultName: 'Antigua & Barbuda',
    localName: {
      de: 'Antigua und Barbuda',
      en: 'Antigua & Barbuda',
      ru: 'Антигуа и Барбуда',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg',
  },
  {
    code: 'AI',
    defaultName: 'Anguilla',
    localName: { de: 'Anguilla', en: 'Anguilla', ru: 'Ангилья' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg',
  },
  {
    code: 'AL',
    defaultName: 'Albania',
    localName: { de: 'Albanien', en: 'Albania', ru: 'Албания' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg',
  },
  {
    code: 'AM',
    defaultName: 'Armenia',
    localName: { de: 'Armenien', en: 'Armenia', ru: 'Армения' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg',
  },
  {
    code: 'AO',
    defaultName: 'Angola',
    localName: { de: 'Angola', en: 'Angola', ru: 'Ангола' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg',
  },
  {
    code: 'AQ',
    defaultName: 'Antarctica',
    localName: { de: 'Antarktis', en: 'Antarctica', ru: 'Антарктида' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg',
  },
  {
    code: 'AR',
    defaultName: 'Argentina',
    localName: { de: 'Argentinien', en: 'Argentina', ru: 'Аргентина' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg',
  },
  {
    code: 'AS',
    defaultName: 'American Samoa',
    localName: {
      de: 'Amerikanisch-Samoa',
      en: 'American Samoa',
      ru: 'Американское Самоа',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg',
  },
  {
    code: 'AT',
    defaultName: 'Austria',
    localName: { de: 'Österreich', en: 'Austria', ru: 'Австрия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg',
  },
  {
    code: 'AU',
    defaultName: 'Australia',
    localName: { de: 'Australien', en: 'Australia', ru: 'Австралия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg',
  },
  {
    code: 'AW',
    defaultName: 'Aruba',
    localName: { de: 'Aruba', en: 'Aruba', ru: 'Аруба' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg',
  },
  {
    code: 'AX',
    defaultName: 'Åland Islands',
    localName: {
      de: 'Ålandinseln',
      en: 'Åland Islands',
      ru: 'Аландские о-ва',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg',
  },
  {
    code: 'AZ',
    defaultName: 'Azerbaijan',
    localName: {
      de: 'Aserbaidschan',
      en: 'Azerbaijan',
      ru: 'Азербайджан',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg',
  },
  {
    code: 'BA',
    defaultName: 'Bosnia & Herzegovina',
    localName: {
      de: 'Bosnien und Herzegowina',
      en: 'Bosnia & Herzegovina',
      ru: 'Босния и Герцеговина',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg',
  },
  {
    code: 'BB',
    defaultName: 'Barbados',
    localName: { de: 'Barbados', en: 'Barbados', ru: 'Барбадос' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg',
  },
  {
    code: 'BD',
    defaultName: 'Bangladesh',
    localName: { de: 'Bangladesch', en: 'Bangladesh', ru: 'Бангладеш' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg',
  },
  {
    code: 'BE',
    defaultName: 'Belgium',
    localName: { de: 'Belgien', en: 'Belgium', ru: 'Бельгия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg',
  },
  {
    code: 'BF',
    defaultName: 'Burkina Faso',
    localName: {
      de: 'Burkina Faso',
      en: 'Burkina Faso',
      ru: 'Буркина-Фасо',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg',
  },
  {
    code: 'BG',
    defaultName: 'Bulgaria',
    localName: { de: 'Bulgarien', en: 'Bulgaria', ru: 'Болгария' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg',
  },
  {
    code: 'BH',
    defaultName: 'Bahrain',
    localName: { de: 'Bahrain', en: 'Bahrain', ru: 'Бахрейн' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg',
  },
  {
    code: 'BI',
    defaultName: 'Burundi',
    localName: { de: 'Burundi', en: 'Burundi', ru: 'Бурунди' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg',
  },
  {
    code: 'BJ',
    defaultName: 'Benin',
    localName: { de: 'Benin', en: 'Benin', ru: 'Бенин' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg',
  },
  {
    code: 'BL',
    defaultName: 'St. Barthélemy',
    localName: {
      de: 'St. Barthélemy',
      en: 'St. Barthélemy',
      ru: 'Сен-Бартелеми',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg',
  },
  {
    code: 'BM',
    defaultName: 'Bermuda',
    localName: { de: 'Bermuda', en: 'Bermuda', ru: 'Бермудские о-ва' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg',
  },
  {
    code: 'BN',
    defaultName: 'Brunei',
    localName: {
      de: 'Brunei Darussalam',
      en: 'Brunei',
      ru: 'Бруней-Даруссалам',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg',
  },
  {
    code: 'BO',
    defaultName: 'Bolivia',
    localName: { de: 'Bolivien', en: 'Bolivia', ru: 'Боливия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg',
  },
  {
    code: 'BQ',
    defaultName: 'Caribbean Netherlands',
    localName: {
      de: 'Bonaire, Sint Eustatius und Saba',
      en: 'Caribbean Netherlands',
      ru: 'Бонэйр, Синт-Эстатиус и Саба',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg',
  },
  {
    code: 'BR',
    defaultName: 'Brazil',
    localName: { de: 'Brasilien', en: 'Brazil', ru: 'Бразилия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg',
  },
  {
    code: 'BS',
    defaultName: 'Bahamas',
    localName: { de: 'Bahamas', en: 'Bahamas', ru: 'Багамы' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg',
  },
  {
    code: 'BT',
    defaultName: 'Bhutan',
    localName: { de: 'Bhutan', en: 'Bhutan', ru: 'Бутан' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg',
  },
  {
    code: 'BV',
    defaultName: 'Bouvet Island',
    localName: {
      de: 'Bouvetinsel',
      en: 'Bouvet Island',
      ru: 'о-в Буве',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg',
  },
  {
    code: 'BW',
    defaultName: 'Botswana',
    localName: { de: 'Botsuana', en: 'Botswana', ru: 'Ботсвана' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg',
  },
  {
    code: 'BY',
    defaultName: 'Belarus',
    localName: { de: 'Belarus', en: 'Belarus', ru: 'Беларусь' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg',
  },
  {
    code: 'BZ',
    defaultName: 'Belize',
    localName: { de: 'Belize', en: 'Belize', ru: 'Белиз' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg',
  },
  {
    code: 'CA',
    defaultName: 'Canada',
    localName: { de: 'Kanada', en: 'Canada', ru: 'Канада' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg',
  },
  {
    code: 'CC',
    defaultName: 'Cocos (Keeling) Islands',
    localName: {
      de: 'Kokosinseln',
      en: 'Cocos (Keeling) Islands',
      ru: 'Кокосовые о-ва',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg',
  },
  {
    code: 'CD',
    defaultName: 'Congo - Kinshasa',
    localName: {
      de: 'Kongo-Kinshasa',
      en: 'Congo - Kinshasa',
      ru: 'Конго - Киншаса',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg',
  },
  {
    code: 'CF',
    defaultName: 'Central African Republic',
    localName: {
      de: 'Zentralafrikanische Republik',
      en: 'Central African Republic',
      ru: 'Центрально-Африканская Республика',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg',
  },
  {
    code: 'CG',
    defaultName: 'Congo - Brazzaville',
    localName: {
      de: 'Kongo-Brazzaville',
      en: 'Congo - Brazzaville',
      ru: 'Конго - Браззавиль',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg',
  },
  {
    code: 'CH',
    defaultName: 'Switzerland',
    localName: { de: 'Schweiz', en: 'Switzerland', ru: 'Швейцария' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg',
  },
  {
    code: 'CI',
    defaultName: 'Côte d’Ivoire',
    localName: {
      de: 'Côte d’Ivoire',
      en: 'Côte d’Ivoire',
      ru: 'Кот-д’Ивуар',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg',
  },
  {
    code: 'CK',
    defaultName: 'Cook Islands',
    localName: {
      de: 'Cookinseln',
      en: 'Cook Islands',
      ru: 'Острова Кука',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg',
  },
  {
    code: 'CL',
    defaultName: 'Chile',
    localName: { de: 'Chile', en: 'Chile', ru: 'Чили' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg',
  },
  {
    code: 'CM',
    defaultName: 'Cameroon',
    localName: { de: 'Kamerun', en: 'Cameroon', ru: 'Камерун' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg',
  },
  {
    code: 'CN',
    defaultName: 'China',
    localName: { de: 'China', en: 'China', ru: 'Китай' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg',
  },
  {
    code: 'CO',
    defaultName: 'Colombia',
    localName: { de: 'Kolumbien', en: 'Colombia', ru: 'Колумбия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg',
  },
  {
    code: 'CR',
    defaultName: 'Costa Rica',
    localName: { de: 'Costa Rica', en: 'Costa Rica', ru: 'Коста-Рика' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg',
  },
  {
    code: 'CU',
    defaultName: 'Cuba',
    localName: { de: 'Kuba', en: 'Cuba', ru: 'Куба' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg',
  },
  {
    code: 'CV',
    defaultName: 'Cape Verde',
    localName: { de: 'Cabo Verde', en: 'Cape Verde', ru: 'Кабо-Верде' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg',
  },
  {
    code: 'CW',
    defaultName: 'Curaçao',
    localName: { de: 'Curaçao', en: 'Curaçao', ru: 'Кюрасао' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg',
  },
  {
    code: 'CX',
    defaultName: 'Christmas Island',
    localName: {
      de: 'Weihnachtsinsel',
      en: 'Christmas Island',
      ru: 'о-в Рождества',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg',
  },
  {
    code: 'CY',
    defaultName: 'Cyprus',
    localName: { de: 'Zypern', en: 'Cyprus', ru: 'Кипр' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg',
  },
  {
    code: 'CZ',
    defaultName: 'Czechia',
    localName: { de: 'Tschechien', en: 'Czechia', ru: 'Чехия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg',
  },
  {
    code: 'DE',
    defaultName: 'Germany',
    localName: { de: 'Deutschland', en: 'Germany', ru: 'Германия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg',
  },
  {
    code: 'DJ',
    defaultName: 'Djibouti',
    localName: { de: 'Dschibuti', en: 'Djibouti', ru: 'Джибути' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg',
  },
  {
    code: 'DK',
    defaultName: 'Denmark',
    localName: { de: 'Dänemark', en: 'Denmark', ru: 'Дания' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg',
  },
  {
    code: 'DM',
    defaultName: 'Dominica',
    localName: { de: 'Dominica', en: 'Dominica', ru: 'Доминика' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg',
  },
  {
    code: 'DO',
    defaultName: 'Dominican Republic',
    localName: {
      de: 'Dominikanische Republik',
      en: 'Dominican Republic',
      ru: 'Доминиканская Республика',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg',
  },
  {
    code: 'DZ',
    defaultName: 'Algeria',
    localName: { de: 'Algerien', en: 'Algeria', ru: 'Алжир' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg',
  },
  {
    code: 'EC',
    defaultName: 'Ecuador',
    localName: { de: 'Ecuador', en: 'Ecuador', ru: 'Эквадор' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg',
  },
  {
    code: 'EE',
    defaultName: 'Estonia',
    localName: { de: 'Estland', en: 'Estonia', ru: 'Эстония' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg',
  },
  {
    code: 'EG',
    defaultName: 'Egypt',
    localName: { de: 'Ägypten', en: 'Egypt', ru: 'Египет' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg',
  },
  {
    code: 'EH',
    defaultName: 'Western Sahara',
    localName: {
      de: 'Westsahara',
      en: 'Western Sahara',
      ru: 'Западная Сахара',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg',
  },
  {
    code: 'ER',
    defaultName: 'Eritrea',
    localName: { de: 'Eritrea', en: 'Eritrea', ru: 'Эритрея' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg',
  },
  {
    code: 'ES',
    defaultName: 'Spain',
    localName: { de: 'Spanien', en: 'Spain', ru: 'Испания' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg',
  },
  {
    code: 'ET',
    defaultName: 'Ethiopia',
    localName: { de: 'Äthiopien', en: 'Ethiopia', ru: 'Эфиопия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg',
  },
  {
    code: 'FI',
    defaultName: 'Finland',
    localName: { de: 'Finnland', en: 'Finland', ru: 'Финляндия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg',
  },
  {
    code: 'FJ',
    defaultName: 'Fiji',
    localName: { de: 'Fidschi', en: 'Fiji', ru: 'Фиджи' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg',
  },
  {
    code: 'FK',
    defaultName: 'Falkland Islands',
    localName: {
      de: 'Falklandinseln',
      en: 'Falkland Islands',
      ru: 'Фолклендские о-ва',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg',
  },
  {
    code: 'FM',
    defaultName: 'Micronesia',
    localName: {
      de: 'Mikronesien',
      en: 'Micronesia',
      ru: 'Федеративные Штаты Микронезии',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg',
  },
  {
    code: 'FO',
    defaultName: 'Faroe Islands',
    localName: {
      de: 'Färöer',
      en: 'Faroe Islands',
      ru: 'Фарерские о-ва',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg',
  },
  {
    code: 'FR',
    defaultName: 'France',
    localName: { de: 'Frankreich', en: 'France', ru: 'Франция' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg',
  },
  {
    code: 'GA',
    defaultName: 'Gabon',
    localName: { de: 'Gabun', en: 'Gabon', ru: 'Габон' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg',
  },
  {
    code: 'GB',
    defaultName: 'United Kingdom',
    localName: {
      de: 'Vereinigtes Königreich',
      en: 'United Kingdom',
      ru: 'Великобритания',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg',
  },
  {
    code: 'GD',
    defaultName: 'Grenada',
    localName: { de: 'Grenada', en: 'Grenada', ru: 'Гренада' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg',
  },
  {
    code: 'GE',
    defaultName: 'Georgia',
    localName: { de: 'Georgien', en: 'Georgia', ru: 'Грузия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg',
  },
  {
    code: 'GF',
    defaultName: 'French Guiana',
    localName: {
      de: 'Französisch-Guayana',
      en: 'French Guiana',
      ru: 'Французская Гвиана',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg',
  },
  {
    code: 'GG',
    defaultName: 'Guernsey',
    localName: { de: 'Guernsey', en: 'Guernsey', ru: 'Гернси' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg',
  },
  {
    code: 'GH',
    defaultName: 'Ghana',
    localName: { de: 'Ghana', en: 'Ghana', ru: 'Гана' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg',
  },
  {
    code: 'GI',
    defaultName: 'Gibraltar',
    localName: { de: 'Gibraltar', en: 'Gibraltar', ru: 'Гибралтар' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg',
  },
  {
    code: 'GL',
    defaultName: 'Greenland',
    localName: { de: 'Grönland', en: 'Greenland', ru: 'Гренландия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg',
  },
  {
    code: 'GM',
    defaultName: 'Gambia',
    localName: { de: 'Gambia', en: 'Gambia', ru: 'Гамбия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg',
  },
  {
    code: 'GN',
    defaultName: 'Guinea',
    localName: { de: 'Guinea', en: 'Guinea', ru: 'Гвинея' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg',
  },
  {
    code: 'GP',
    defaultName: 'Guadeloupe',
    localName: { de: 'Guadeloupe', en: 'Guadeloupe', ru: 'Гваделупа' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg',
  },
  {
    code: 'GQ',
    defaultName: 'Equatorial Guinea',
    localName: {
      de: 'Äquatorialguinea',
      en: 'Equatorial Guinea',
      ru: 'Экваториальная Гвинея',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg',
  },
  {
    code: 'GR',
    defaultName: 'Greece',
    localName: { de: 'Griechenland', en: 'Greece', ru: 'Греция' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg',
  },
  {
    code: 'GS',
    defaultName: 'South Georgia & South Sandwich Islands',
    localName: {
      de: 'Südgeorgien und die Südlichen Sandwichinseln',
      en: 'South Georgia & South Sandwich Islands',
      ru: 'Южная Георгия и Южные Сандвичевы о-ва',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg',
  },
  {
    code: 'GT',
    defaultName: 'Guatemala',
    localName: { de: 'Guatemala', en: 'Guatemala', ru: 'Гватемала' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg',
  },
  {
    code: 'GU',
    defaultName: 'Guam',
    localName: { de: 'Guam', en: 'Guam', ru: 'Гуам' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg',
  },
  {
    code: 'GW',
    defaultName: 'Guinea-Bissau',
    localName: {
      de: 'Guinea-Bissau',
      en: 'Guinea-Bissau',
      ru: 'Гвинея-Бисау',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg',
  },
  {
    code: 'GY',
    defaultName: 'Guyana',
    localName: { de: 'Guyana', en: 'Guyana', ru: 'Гайана' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg',
  },
  {
    code: 'HK',
    defaultName: 'Hong Kong SAR China',
    localName: {
      de: 'Sonderverwaltungsregion Hongkong',
      en: 'Hong Kong SAR China',
      ru: 'Гонконг (САР)',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg',
  },
  {
    code: 'HM',
    defaultName: 'Heard & McDonald Islands',
    localName: {
      de: 'Heard und McDonaldinseln',
      en: 'Heard & McDonald Islands',
      ru: 'о-ва Херд и Макдональд',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg',
  },
  {
    code: 'HN',
    defaultName: 'Honduras',
    localName: { de: 'Honduras', en: 'Honduras', ru: 'Гондурас' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg',
  },
  {
    code: 'HR',
    defaultName: 'Croatia',
    localName: { de: 'Kroatien', en: 'Croatia', ru: 'Хорватия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg',
  },
  {
    code: 'HT',
    defaultName: 'Haiti',
    localName: { de: 'Haiti', en: 'Haiti', ru: 'Гаити' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg',
  },
  {
    code: 'HU',
    defaultName: 'Hungary',
    localName: { de: 'Ungarn', en: 'Hungary', ru: 'Венгрия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg',
  },
  {
    code: 'ID',
    defaultName: 'Indonesia',
    localName: { de: 'Indonesien', en: 'Indonesia', ru: 'Индонезия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg',
  },
  {
    code: 'IE',
    defaultName: 'Ireland',
    localName: { de: 'Irland', en: 'Ireland', ru: 'Ирландия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg',
  },
  {
    code: 'IL',
    defaultName: 'Israel',
    localName: { de: 'Israel', en: 'Israel', ru: 'Израиль' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg',
  },
  {
    code: 'IM',
    defaultName: 'Isle of Man',
    localName: { de: 'Isle of Man', en: 'Isle of Man', ru: 'о-в Мэн' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg',
  },
  {
    code: 'IN',
    defaultName: 'India',
    localName: { de: 'Indien', en: 'India', ru: 'Индия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg',
  },
  {
    code: 'IO',
    defaultName: 'British Indian Ocean Territory',
    localName: {
      de: 'Britisches Territorium im Indischen Ozean',
      en: 'British Indian Ocean Territory',
      ru: 'Британская территория в Индийском океане',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg',
  },
  {
    code: 'IQ',
    defaultName: 'Iraq',
    localName: { de: 'Irak', en: 'Iraq', ru: 'Ирак' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg',
  },
  {
    code: 'IR',
    defaultName: 'Iran',
    localName: { de: 'Iran', en: 'Iran', ru: 'Иран' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg',
  },
  {
    code: 'IS',
    defaultName: 'Iceland',
    localName: { de: 'Island', en: 'Iceland', ru: 'Исландия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg',
  },
  {
    code: 'IT',
    defaultName: 'Italy',
    localName: { de: 'Italien', en: 'Italy', ru: 'Италия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg',
  },
  {
    code: 'JE',
    defaultName: 'Jersey',
    localName: { de: 'Jersey', en: 'Jersey', ru: 'Джерси' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg',
  },
  {
    code: 'JM',
    defaultName: 'Jamaica',
    localName: { de: 'Jamaika', en: 'Jamaica', ru: 'Ямайка' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg',
  },
  {
    code: 'JO',
    defaultName: 'Jordan',
    localName: { de: 'Jordanien', en: 'Jordan', ru: 'Иордания' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg',
  },
  {
    code: 'JP',
    defaultName: 'Japan',
    localName: { de: 'Japan', en: 'Japan', ru: 'Япония' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg',
  },
  {
    code: 'KE',
    defaultName: 'Kenya',
    localName: { de: 'Kenia', en: 'Kenya', ru: 'Кения' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg',
  },
  {
    code: 'KG',
    defaultName: 'Kyrgyzstan',
    localName: { de: 'Kirgisistan', en: 'Kyrgyzstan', ru: 'Киргизия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg',
  },
  {
    code: 'KH',
    defaultName: 'Cambodia',
    localName: { de: 'Kambodscha', en: 'Cambodia', ru: 'Камбоджа' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg',
  },
  {
    code: 'KI',
    defaultName: 'Kiribati',
    localName: { de: 'Kiribati', en: 'Kiribati', ru: 'Кирибати' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg',
  },
  {
    code: 'KM',
    defaultName: 'Comoros',
    localName: { de: 'Komoren', en: 'Comoros', ru: 'Коморы' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg',
  },
  {
    code: 'KN',
    defaultName: 'St. Kitts & Nevis',
    localName: {
      de: 'St. Kitts und Nevis',
      en: 'St. Kitts & Nevis',
      ru: 'Сент-Китс и Невис',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg',
  },
  {
    code: 'KP',
    defaultName: 'North Korea',
    localName: { de: 'Nordkorea', en: 'North Korea', ru: 'КНДР' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg',
  },
  {
    code: 'KR',
    defaultName: 'South Korea',
    localName: {
      de: 'Südkorea',
      en: 'South Korea',
      ru: 'Республика Корея',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg',
  },
  {
    code: 'KW',
    defaultName: 'Kuwait',
    localName: { de: 'Kuwait', en: 'Kuwait', ru: 'Кувейт' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg',
  },
  {
    code: 'KY',
    defaultName: 'Cayman Islands',
    localName: {
      de: 'Kaimaninseln',
      en: 'Cayman Islands',
      ru: 'Острова Кайман',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg',
  },
  {
    code: 'KZ',
    defaultName: 'Kazakhstan',
    localName: { de: 'Kasachstan', en: 'Kazakhstan', ru: 'Казахстан' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg',
  },
  {
    code: 'LA',
    defaultName: 'Laos',
    localName: { de: 'Laos', en: 'Laos', ru: 'Лаос' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg',
  },
  {
    code: 'LB',
    defaultName: 'Lebanon',
    localName: { de: 'Libanon', en: 'Lebanon', ru: 'Ливан' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg',
  },
  {
    code: 'LC',
    defaultName: 'St. Lucia',
    localName: { de: 'St. Lucia', en: 'St. Lucia', ru: 'Сент-Люсия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg',
  },
  {
    code: 'LI',
    defaultName: 'Liechtenstein',
    localName: {
      de: 'Liechtenstein',
      en: 'Liechtenstein',
      ru: 'Лихтенштейн',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg',
  },
  {
    code: 'LK',
    defaultName: 'Sri Lanka',
    localName: { de: 'Sri Lanka', en: 'Sri Lanka', ru: 'Шри-Ланка' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg',
  },
  {
    code: 'LR',
    defaultName: 'Liberia',
    localName: { de: 'Liberia', en: 'Liberia', ru: 'Либерия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg',
  },
  {
    code: 'LS',
    defaultName: 'Lesotho',
    localName: { de: 'Lesotho', en: 'Lesotho', ru: 'Лесото' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg',
  },
  {
    code: 'LT',
    defaultName: 'Lithuania',
    localName: { de: 'Litauen', en: 'Lithuania', ru: 'Литва' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg',
  },
  {
    code: 'LU',
    defaultName: 'Luxembourg',
    localName: { de: 'Luxemburg', en: 'Luxembourg', ru: 'Люксембург' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg',
  },
  {
    code: 'LV',
    defaultName: 'Latvia',
    localName: { de: 'Lettland', en: 'Latvia', ru: 'Латвия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg',
  },
  {
    code: 'LY',
    defaultName: 'Libya',
    localName: { de: 'Libyen', en: 'Libya', ru: 'Ливия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg',
  },
  {
    code: 'MA',
    defaultName: 'Morocco',
    localName: { de: 'Marokko', en: 'Morocco', ru: 'Марокко' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg',
  },
  {
    code: 'MC',
    defaultName: 'Monaco',
    localName: { de: 'Monaco', en: 'Monaco', ru: 'Монако' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg',
  },
  {
    code: 'MD',
    defaultName: 'Moldova',
    localName: { de: 'Republik Moldau', en: 'Moldova', ru: 'Молдова' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg',
  },
  {
    code: 'ME',
    defaultName: 'Montenegro',
    localName: { de: 'Montenegro', en: 'Montenegro', ru: 'Черногория' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg',
  },
  {
    code: 'MF',
    defaultName: 'St. Martin',
    localName: { de: 'St. Martin', en: 'St. Martin', ru: 'Сен-Мартен' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg',
  },
  {
    code: 'MG',
    defaultName: 'Madagascar',
    localName: { de: 'Madagaskar', en: 'Madagascar', ru: 'Мадагаскар' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg',
  },
  {
    code: 'MH',
    defaultName: 'Marshall Islands',
    localName: {
      de: 'Marshallinseln',
      en: 'Marshall Islands',
      ru: 'Маршалловы Острова',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg',
  },
  {
    code: 'MK',
    defaultName: 'North Macedonia',
    localName: {
      de: 'Nordmazedonien',
      en: 'North Macedonia',
      ru: 'Северная Македония',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg',
  },
  {
    code: 'ML',
    defaultName: 'Mali',
    localName: { de: 'Mali', en: 'Mali', ru: 'Мали' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg',
  },
  {
    code: 'MM',
    defaultName: 'Myanmar (Burma)',
    localName: {
      de: 'Myanmar',
      en: 'Myanmar (Burma)',
      ru: 'Мьянма (Бирма)',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg',
  },
  {
    code: 'MN',
    defaultName: 'Mongolia',
    localName: { de: 'Mongolei', en: 'Mongolia', ru: 'Монголия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg',
  },
  {
    code: 'MO',
    defaultName: 'Macao SAR China',
    localName: {
      de: 'Sonderverwaltungsregion Macau',
      en: 'Macao SAR China',
      ru: 'Макао (САР)',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg',
  },
  {
    code: 'MP',
    defaultName: 'Northern Mariana Islands',
    localName: {
      de: 'Nördliche Marianen',
      en: 'Northern Mariana Islands',
      ru: 'Северные Марианские о-ва',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg',
  },
  {
    code: 'MQ',
    defaultName: 'Martinique',
    localName: { de: 'Martinique', en: 'Martinique', ru: 'Мартиника' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg',
  },
  {
    code: 'MR',
    defaultName: 'Mauritania',
    localName: {
      de: 'Mauretanien',
      en: 'Mauritania',
      ru: 'Мавритания',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg',
  },
  {
    code: 'MS',
    defaultName: 'Montserrat',
    localName: { de: 'Montserrat', en: 'Montserrat', ru: 'Монтсеррат' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg',
  },
  {
    code: 'MT',
    defaultName: 'Malta',
    localName: { de: 'Malta', en: 'Malta', ru: 'Мальта' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg',
  },
  {
    code: 'MU',
    defaultName: 'Mauritius',
    localName: { de: 'Mauritius', en: 'Mauritius', ru: 'Маврикий' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg',
  },
  {
    code: 'MV',
    defaultName: 'Maldives',
    localName: { de: 'Malediven', en: 'Maldives', ru: 'Мальдивы' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg',
  },
  {
    code: 'MW',
    defaultName: 'Malawi',
    localName: { de: 'Malawi', en: 'Malawi', ru: 'Малави' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg',
  },
  {
    code: 'MX',
    defaultName: 'Mexico',
    localName: { de: 'Mexiko', en: 'Mexico', ru: 'Мексика' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg',
  },
  {
    code: 'MY',
    defaultName: 'Malaysia',
    localName: { de: 'Malaysia', en: 'Malaysia', ru: 'Малайзия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg',
  },
  {
    code: 'MZ',
    defaultName: 'Mozambique',
    localName: { de: 'Mosambik', en: 'Mozambique', ru: 'Мозамбик' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg',
  },
  {
    code: 'NA',
    defaultName: 'Namibia',
    localName: { de: 'Namibia', en: 'Namibia', ru: 'Намибия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg',
  },
  {
    code: 'NC',
    defaultName: 'New Caledonia',
    localName: {
      de: 'Neukaledonien',
      en: 'New Caledonia',
      ru: 'Новая Каледония',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg',
  },
  {
    code: 'NE',
    defaultName: 'Niger',
    localName: { de: 'Niger', en: 'Niger', ru: 'Нигер' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg',
  },
  {
    code: 'NF',
    defaultName: 'Norfolk Island',
    localName: {
      de: 'Norfolkinsel',
      en: 'Norfolk Island',
      ru: 'о-в Норфолк',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg',
  },
  {
    code: 'NG',
    defaultName: 'Nigeria',
    localName: { de: 'Nigeria', en: 'Nigeria', ru: 'Нигерия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg',
  },
  {
    code: 'NI',
    defaultName: 'Nicaragua',
    localName: { de: 'Nicaragua', en: 'Nicaragua', ru: 'Никарагуа' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg',
  },
  {
    code: 'NL',
    defaultName: 'Netherlands',
    localName: {
      de: 'Niederlande',
      en: 'Netherlands',
      ru: 'Нидерланды',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg',
  },
  {
    code: 'NO',
    defaultName: 'Norway',
    localName: { de: 'Norwegen', en: 'Norway', ru: 'Норвегия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg',
  },
  {
    code: 'NP',
    defaultName: 'Nepal',
    localName: { de: 'Nepal', en: 'Nepal', ru: 'Непал' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg',
  },
  {
    code: 'NR',
    defaultName: 'Nauru',
    localName: { de: 'Nauru', en: 'Nauru', ru: 'Науру' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg',
  },
  {
    code: 'NU',
    defaultName: 'Niue',
    localName: { de: 'Niue', en: 'Niue', ru: 'Ниуэ' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg',
  },
  {
    code: 'NZ',
    defaultName: 'New Zealand',
    localName: {
      de: 'Neuseeland',
      en: 'New Zealand',
      ru: 'Новая Зеландия',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg',
  },
  {
    code: 'OM',
    defaultName: 'Oman',
    localName: { de: 'Oman', en: 'Oman', ru: 'Оман' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg',
  },
  {
    code: 'PA',
    defaultName: 'Panama',
    localName: { de: 'Panama', en: 'Panama', ru: 'Панама' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg',
  },
  {
    code: 'PE',
    defaultName: 'Peru',
    localName: { de: 'Peru', en: 'Peru', ru: 'Перу' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg',
  },
  {
    code: 'PF',
    defaultName: 'French Polynesia',
    localName: {
      de: 'Französisch-Polynesien',
      en: 'French Polynesia',
      ru: 'Французская Полинезия',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg',
  },
  {
    code: 'PG',
    defaultName: 'Papua New Guinea',
    localName: {
      de: 'Papua-Neuguinea',
      en: 'Papua New Guinea',
      ru: 'Папуа — Новая Гвинея',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg',
  },
  {
    code: 'PH',
    defaultName: 'Philippines',
    localName: {
      de: 'Philippinen',
      en: 'Philippines',
      ru: 'Филиппины',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg',
  },
  {
    code: 'PK',
    defaultName: 'Pakistan',
    localName: { de: 'Pakistan', en: 'Pakistan', ru: 'Пакистан' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg',
  },
  {
    code: 'PL',
    defaultName: 'Poland',
    localName: { de: 'Polen', en: 'Poland', ru: 'Польша' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg',
  },
  {
    code: 'PM',
    defaultName: 'St. Pierre & Miquelon',
    localName: {
      de: 'St. Pierre und Miquelon',
      en: 'St. Pierre & Miquelon',
      ru: 'Сен-Пьер и Микелон',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg',
  },
  {
    code: 'PN',
    defaultName: 'Pitcairn Islands',
    localName: {
      de: 'Pitcairninseln',
      en: 'Pitcairn Islands',
      ru: 'о-ва Питкэрн',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg',
  },
  {
    code: 'PR',
    defaultName: 'Puerto Rico',
    localName: {
      de: 'Puerto Rico',
      en: 'Puerto Rico',
      ru: 'Пуэрто-Рико',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg',
  },
  {
    code: 'PS',
    defaultName: 'Palestinian Territories',
    localName: {
      de: 'Palästinensische Autonomiegebiete',
      en: 'Palestinian Territories',
      ru: 'Палестинские территории',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg',
  },
  {
    code: 'PT',
    defaultName: 'Portugal',
    localName: { de: 'Portugal', en: 'Portugal', ru: 'Португалия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg',
  },
  {
    code: 'PW',
    defaultName: 'Palau',
    localName: { de: 'Palau', en: 'Palau', ru: 'Палау' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg',
  },
  {
    code: 'PY',
    defaultName: 'Paraguay',
    localName: { de: 'Paraguay', en: 'Paraguay', ru: 'Парагвай' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg',
  },
  {
    code: 'QA',
    defaultName: 'Qatar',
    localName: { de: 'Katar', en: 'Qatar', ru: 'Катар' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg',
  },
  {
    code: 'RE',
    defaultName: 'Réunion',
    localName: { de: 'Réunion', en: 'Réunion', ru: 'Реюньон' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg',
  },
  {
    code: 'RO',
    defaultName: 'Romania',
    localName: { de: 'Rumänien', en: 'Romania', ru: 'Румыния' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg',
  },
  {
    code: 'RS',
    defaultName: 'Serbia',
    localName: { de: 'Serbien', en: 'Serbia', ru: 'Сербия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg',
  },
  {
    code: 'RU',
    defaultName: 'Russia',
    localName: { de: 'Russland', en: 'Russia', ru: 'Россия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg',
  },
  {
    code: 'RW',
    defaultName: 'Rwanda',
    localName: { de: 'Ruanda', en: 'Rwanda', ru: 'Руанда' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg',
  },
  {
    code: 'SA',
    defaultName: 'Saudi Arabia',
    localName: {
      de: 'Saudi-Arabien',
      en: 'Saudi Arabia',
      ru: 'Саудовская Аравия',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg',
  },
  {
    code: 'SB',
    defaultName: 'Solomon Islands',
    localName: {
      de: 'Salomonen',
      en: 'Solomon Islands',
      ru: 'Соломоновы Острова',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg',
  },
  {
    code: 'SC',
    defaultName: 'Seychelles',
    localName: {
      de: 'Seychellen',
      en: 'Seychelles',
      ru: 'Сейшельские Острова',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg',
  },
  {
    code: 'SD',
    defaultName: 'Sudan',
    localName: { de: 'Sudan', en: 'Sudan', ru: 'Судан' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg',
  },
  {
    code: 'SE',
    defaultName: 'Sweden',
    localName: { de: 'Schweden', en: 'Sweden', ru: 'Швеция' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg',
  },
  {
    code: 'SG',
    defaultName: 'Singapore',
    localName: { de: 'Singapur', en: 'Singapore', ru: 'Сингапур' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg',
  },
  {
    code: 'SH',
    defaultName: 'St. Helena',
    localName: {
      de: 'St. Helena',
      en: 'St. Helena',
      ru: 'о-в Св. Елены',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg',
  },
  {
    code: 'SI',
    defaultName: 'Slovenia',
    localName: { de: 'Slowenien', en: 'Slovenia', ru: 'Словения' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg',
  },
  {
    code: 'SJ',
    defaultName: 'Svalbard & Jan Mayen',
    localName: {
      de: 'Spitzbergen und Jan Mayen',
      en: 'Svalbard & Jan Mayen',
      ru: 'Шпицберген и Ян-Майен',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg',
  },
  {
    code: 'SK',
    defaultName: 'Slovakia',
    localName: { de: 'Slowakei', en: 'Slovakia', ru: 'Словакия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg',
  },
  {
    code: 'SL',
    defaultName: 'Sierra Leone',
    localName: {
      de: 'Sierra Leone',
      en: 'Sierra Leone',
      ru: 'Сьерра-Леоне',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg',
  },
  {
    code: 'SM',
    defaultName: 'San Marino',
    localName: { de: 'San Marino', en: 'San Marino', ru: 'Сан-Марино' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg',
  },
  {
    code: 'SN',
    defaultName: 'Senegal',
    localName: { de: 'Senegal', en: 'Senegal', ru: 'Сенегал' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg',
  },
  {
    code: 'SO',
    defaultName: 'Somalia',
    localName: { de: 'Somalia', en: 'Somalia', ru: 'Сомали' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg',
  },
  {
    code: 'SR',
    defaultName: 'Suriname',
    localName: { de: 'Suriname', en: 'Suriname', ru: 'Суринам' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg',
  },
  {
    code: 'SS',
    defaultName: 'South Sudan',
    localName: { de: 'Südsudan', en: 'South Sudan', ru: 'Южный Судан' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg',
  },
  {
    code: 'ST',
    defaultName: 'São Tomé & Príncipe',
    localName: {
      de: 'São Tomé und Príncipe',
      en: 'São Tomé & Príncipe',
      ru: 'Сан-Томе и Принсипи',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg',
  },
  {
    code: 'SV',
    defaultName: 'El Salvador',
    localName: {
      de: 'El Salvador',
      en: 'El Salvador',
      ru: 'Сальвадор',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg',
  },
  {
    code: 'SX',
    defaultName: 'Sint Maarten',
    localName: {
      de: 'Sint Maarten',
      en: 'Sint Maarten',
      ru: 'Синт-Мартен',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg',
  },
  {
    code: 'SY',
    defaultName: 'Syria',
    localName: { de: 'Syrien', en: 'Syria', ru: 'Сирия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg',
  },
  {
    code: 'SZ',
    defaultName: 'Eswatini',
    localName: { de: 'Eswatini', en: 'Eswatini', ru: 'Эсватини' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg',
  },
  {
    code: 'TC',
    defaultName: 'Turks & Caicos Islands',
    localName: {
      de: 'Turks- und Caicosinseln',
      en: 'Turks & Caicos Islands',
      ru: 'о-ва Тёркс и Кайкос',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg',
  },
  {
    code: 'TD',
    defaultName: 'Chad',
    localName: { de: 'Tschad', en: 'Chad', ru: 'Чад' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg',
  },
  {
    code: 'TF',
    defaultName: 'French Southern Territories',
    localName: {
      de: 'Französische Süd- und Antarktisgebiete',
      en: 'French Southern Territories',
      ru: 'Французские Южные территории',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg',
  },
  {
    code: 'TG',
    defaultName: 'Togo',
    localName: { de: 'Togo', en: 'Togo', ru: 'Того' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg',
  },
  {
    code: 'TH',
    defaultName: 'Thailand',
    localName: { de: 'Thailand', en: 'Thailand', ru: 'Таиланд' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg',
  },
  {
    code: 'TJ',
    defaultName: 'Tajikistan',
    localName: {
      de: 'Tadschikistan',
      en: 'Tajikistan',
      ru: 'Таджикистан',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg',
  },
  {
    code: 'TK',
    defaultName: 'Tokelau',
    localName: { de: 'Tokelau', en: 'Tokelau', ru: 'Токелау' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg',
  },
  {
    code: 'TL',
    defaultName: 'Timor-Leste',
    localName: {
      de: 'Timor-Leste',
      en: 'Timor-Leste',
      ru: 'Восточный Тимор',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg',
  },
  {
    code: 'TM',
    defaultName: 'Turkmenistan',
    localName: {
      de: 'Turkmenistan',
      en: 'Turkmenistan',
      ru: 'Туркменистан',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg',
  },
  {
    code: 'TN',
    defaultName: 'Tunisia',
    localName: { de: 'Tunesien', en: 'Tunisia', ru: 'Тунис' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg',
  },
  {
    code: 'TO',
    defaultName: 'Tonga',
    localName: { de: 'Tonga', en: 'Tonga', ru: 'Тонга' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg',
  },
  {
    code: 'TR',
    defaultName: 'Turkey',
    localName: { de: 'Türkei', en: 'Turkey', ru: 'Турция' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg',
  },
  {
    code: 'TT',
    defaultName: 'Trinidad & Tobago',
    localName: {
      de: 'Trinidad und Tobago',
      en: 'Trinidad & Tobago',
      ru: 'Тринидад и Тобаго',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg',
  },
  {
    code: 'TV',
    defaultName: 'Tuvalu',
    localName: { de: 'Tuvalu', en: 'Tuvalu', ru: 'Тувалу' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg',
  },
  {
    code: 'TW',
    defaultName: 'Taiwan',
    localName: { de: 'Taiwan', en: 'Taiwan', ru: 'Тайвань' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg',
  },
  {
    code: 'TZ',
    defaultName: 'Tanzania',
    localName: { de: 'Tansania', en: 'Tanzania', ru: 'Танзания' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg',
  },
  {
    code: 'UA',
    defaultName: 'Ukraine',
    localName: { de: 'Ukraine', en: 'Ukraine', ru: 'Украина' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg',
  },
  {
    code: 'UG',
    defaultName: 'Uganda',
    localName: { de: 'Uganda', en: 'Uganda', ru: 'Уганда' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg',
  },
  {
    code: 'UM',
    defaultName: 'U.S. Outlying Islands',
    localName: {
      de: 'Amerikanische Überseeinseln',
      en: 'U.S. Outlying Islands',
      ru: 'Внешние малые о-ва (США)',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg',
  },
  {
    code: 'US',
    defaultName: 'United States',
    localName: {
      de: 'Vereinigte Staaten',
      en: 'United States',
      ru: 'Соединенные Штаты',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg',
  },
  {
    code: 'UY',
    defaultName: 'Uruguay',
    localName: { de: 'Uruguay', en: 'Uruguay', ru: 'Уругвай' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg',
  },
  {
    code: 'UZ',
    defaultName: 'Uzbekistan',
    localName: { de: 'Usbekistan', en: 'Uzbekistan', ru: 'Узбекистан' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg',
  },
  {
    code: 'VA',
    defaultName: 'Vatican City',
    localName: {
      de: 'Vatikanstadt',
      en: 'Vatican City',
      ru: 'Ватикан',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg',
  },
  {
    code: 'VC',
    defaultName: 'St. Vincent & Grenadines',
    localName: {
      de: 'St. Vincent und die Grenadinen',
      en: 'St. Vincent & Grenadines',
      ru: 'Сент-Винсент и Гренадины',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg',
  },
  {
    code: 'VE',
    defaultName: 'Venezuela',
    localName: { de: 'Venezuela', en: 'Venezuela', ru: 'Венесуэла' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg',
  },
  {
    code: 'VG',
    defaultName: 'British Virgin Islands',
    localName: {
      de: 'Britische Jungferninseln',
      en: 'British Virgin Islands',
      ru: 'Виргинские о-ва (Великобритания)',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg',
  },
  {
    code: 'VI',
    defaultName: 'U.S. Virgin Islands',
    localName: {
      de: 'Amerikanische Jungferninseln',
      en: 'U.S. Virgin Islands',
      ru: 'Виргинские о-ва (США)',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg',
  },
  {
    code: 'VN',
    defaultName: 'Vietnam',
    localName: { de: 'Vietnam', en: 'Vietnam', ru: 'Вьетнам' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg',
  },
  {
    code: 'VU',
    defaultName: 'Vanuatu',
    localName: { de: 'Vanuatu', en: 'Vanuatu', ru: 'Вануату' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg',
  },
  {
    code: 'WF',
    defaultName: 'Wallis & Futuna',
    localName: {
      de: 'Wallis und Futuna',
      en: 'Wallis & Futuna',
      ru: 'Уоллис и Футуна',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg',
  },
  {
    code: 'WS',
    defaultName: 'Samoa',
    localName: { de: 'Samoa', en: 'Samoa', ru: 'Самоа' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg',
  },
  {
    code: 'YE',
    defaultName: 'Yemen',
    localName: { de: 'Jemen', en: 'Yemen', ru: 'Йемен' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg',
  },
  {
    code: 'YT',
    defaultName: 'Mayotte',
    localName: { de: 'Mayotte', en: 'Mayotte', ru: 'Майотта' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg',
  },
  {
    code: 'ZA',
    defaultName: 'South Africa',
    localName: {
      de: 'Südafrika',
      en: 'South Africa',
      ru: 'Южно-Африканская Республика',
    },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg',
  },
  {
    code: 'ZM',
    defaultName: 'Zambia',
    localName: { de: 'Sambia', en: 'Zambia', ru: 'Замбия' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg',
  },
  {
    code: 'ZW',
    defaultName: 'Zimbabwe',
    localName: { de: 'Simbabwe', en: 'Zimbabwe', ru: 'Зимбабве' },
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg',
  },
];
