<app-close-button class="modal" (click)="closeDialog()"></app-close-button>

<span i18n class="t-heading-3 t-medium title">Вход</span>

<span class="t-subtitle no-account">
  <ng-container i18n>Еще нет VOKA аккаунта?</ng-container>
  &nbsp;<span i18n class="link" (click)="openSignUp()">Регистрация</span></span
>

<form class="form" [formGroup]="loginForm" (ngSubmit)="login()">
  <app-input
    formControlName="email"
    type="email"
    i18n-label
    label="Электронная почта"
    [errorsMap]="{
      email: 'email',
      required: 'email',
    }"
    [markedAsRequired]="true">
  </app-input>
  <app-input
    formControlName="password"
    type="password"
    i18n-label
    label="Пароль"
    [errorsMap]="{
      required: 'password',
      length: 'password',
    }"
    [maxlength]="maxPasswordLength"
    [markedAsRequired]="true"></app-input>

  <a i18n class="forgot t-subtitle t-semi-bold" (click)="forgotPassword()">
    Забыли пароль?
  </a>

  <button
    appButton
    i18n
    type="submit"
    class="submit"
    id="button-sign-in"
    [disabled]="userLoggingIn"
    [loading]="userLoggingIn"
    >Войти</button
  >
</form>
