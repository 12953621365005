import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { countries } from '@app/shared/models/constants/countries';
import {
  ICountry,
  ICountryDTO,
} from '../../models/interfaces/country.interface';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private _localizedCountriesList: ICountry[] = [];

  public get localizedCountriesList(): ICountry[] {
    return this._localizedCountriesList;
  }

  public get fulfilledCountriesList(): ICountryDTO[] {
    return this.countriesList;
  }

  private countriesList: ICountryDTO[] = countries;

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public setupLocalizedCountries(): void {
    if (this._localizedCountriesList.length) return;

    this._localizedCountriesList = [
      {
        name: $localize`Не выбрано`,
        image: null,
        code: null,
      },
      ...this.countriesList
        .map(country => ({
          name: country.localName[this.locale] || country.defaultName,
          image: country.image,
          code: country.code,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    ];
  }

  public getCountryCodeByCountryName(countryName: string): string {
    return (
      this.localizedCountriesList.find(country => country.name === countryName)
        ?.code || null
    );
  }

  public getCountryNameByCountryCode(countryCode: string): string {
    return (
      this.localizedCountriesList.find(country => country.code === countryCode)
        ?.name || null
    );
  }
}
