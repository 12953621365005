import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  Input,
  TemplateRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';

import { FilterByKeyPipe } from '../../pipes/filter-by-key/filter-by-key.pipe';
import { BaseControlDirective } from '../base-control.directive';

type Option = Object & {
  name: string;
};

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['../controls-styles.scss', './autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FilterByKeyPipe,
    NgClass,
    NgTemplateOutlet,
    ReactiveFormsModule,
    SvgIconComponent,
  ],
})
export class AutocompleteComponent extends BaseControlDirective {
  @HostListener('window:click', ['$event'])
  public clickedOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.optionsOpened = false;
    }
  }

  @Input() public set options(data: Option[]) {
    this.shownOptions = data;
    this.initialOptions = data;
  }

  @ContentChild('item') public itemRef!: TemplateRef<any>;

  public emptyControl = true;

  public inputInFocus = false;

  public optionsOpened: boolean = false;

  public shownOptions: Option[] = [];

  private elementRef = inject(ElementRef<AutocompleteComponent>);

  private initialOptions: Option[] = [];

  constructor(
    protected override ngControl: NgControl,
    protected override cdr: ChangeDetectorRef,
    protected override destroyRef: DestroyRef
  ) {
    super(ngControl, cdr, destroyRef);

    this.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(controlValue => {
        this.emptyControl = !controlValue;
        this.findOption(controlValue);
        this.cdr.markForCheck();
      });
  }

  public openOptionsList(): void {
    this.optionsOpened = true;
    this.cdr.markForCheck();
  }

  public selectOption(option: string): void {
    this.formControl.setValue(option);
    this.closeOptionsList();
  }

  public setInputFocusStatus(status: boolean) {
    this.inputInFocus = status;
    this.cdr.markForCheck();
  }

  private closeOptionsList(): void {
    this.optionsOpened = false;
    this.cdr.markForCheck();
  }

  private findOption(searchValue: string): void {
    this.shownOptions = this.initialOptions.filter(option =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
}
