<app-close-button class="modal" (click)="closeDialog()"></app-close-button>
@if (isForgot) {
  <form class="content" [formGroup]="forgotPassForm" (ngSubmit)="getLink()">
    <span i18n class="t-heading-3 t-bold heading"> Забыли пароль? </span>
    <span i18n class="t-subtitle text">
      Введите адрес электронной почты, и мы отправим ссылку для восстановления
      пароля
    </span>

    <app-input
      class="email"
      formControlName="email"
      type="email"
      label="Электронная почта"
      i18n-label
      [errorsMap]="{ required: 'email', email: 'email' }"
      [markedAsRequired]="true">
    </app-input>

    <button
      appButton
      class="button"
      i18n
      type="submit"
      [disabled]="isGettingLink"
      [loading]="isGettingLink">
      Получить ссылку
    </button>
  </form>
} @else {
  <div class="content">
    <span i18n class="header notification"> Проверьте вашу почту </span>
    <span i18n class="text">
      Мы отправили письмо для восстановления пароля на
      <span class="accent-color">{{ email }}</span
      >. Пожалуйста, в течение 24 часов перейдите по ссылке в письме, чтобы
      установить новый пароль
    </span>

    <button appButton i18n class="button" (click)="closeDialog()">
      Понятно, спасибо
    </button>
  </div>
}
