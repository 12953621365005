import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';
import { getAllCategoriesForce } from '@store/categories/categories.actions';
import { loginUser } from '@store/user/user.actions';
import { selectUserInfo } from '@store/user/user.selectors';
import { interval, take, tap } from 'rxjs';
import { AuthApiService } from '../../api/auth/auth-api.service';
import { UserApiService } from '../../api/user/user-api.service';
import { ColorThemeImageDirective } from '../../directives/color-theme-image/color-theme-image.directive';
import { AlertStatus } from '../../models/enums/alert-status.enum';
import { AlertService } from '../../services/alert.service';
import { BtnDirective } from '../btn/btn.directive';
import { CloseButtonComponent } from '../close-button/close-button.component';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-account-verification',
  standalone: true,
  imports: [
    BtnDirective,
    CloseButtonComponent,
    ColorThemeImageDirective,
    SvgIconComponent,
  ],
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountVerificationComponent {
  public isTimerActive = signal<boolean>(false);

  public remainingTime = signal<string>('59');

  constructor(
    @Inject(DIALOG_DATA) public dialogData: { email: string; password: string },
    private alertService: AlertService,
    private authApiService: AuthApiService,
    private destroyRef: DestroyRef,
    private dialog: Dialog,
    private dialogRef: DialogRef<any, AccountVerificationComponent>,
    private store: Store,
    private userApiService: UserApiService
  ) {}

  public resendLetter(): void {
    this.authApiService
      .resendActivationLetter$(this.dialogData?.email)
      .subscribe(() => {
        this.alertService.show({
          message: $localize`Письмо для активации отправлено`,
          status: AlertStatus.Success,
        });
        this.startTimer();
      });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public openLogin(): void {
    this.dialog.open(LoginComponent);
    this.close();
  }

  private login(email: string, password: string): void {
    this.store.dispatch(loginUser({ loginData: { email, password } }));
  }

  public checkEmail(): void {
    this.userApiService
      .checkEmail$(this.dialogData.email)
      .subscribe(checkedEmailData => {
        if (checkedEmailData.enabled) {
          if (this.dialogData.password) {
            this.store
              .select(selectUserInfo)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(user => {
                if (user) {
                  this.store.dispatch(getAllCategoriesForce());
                }
              });

            this.login(this.dialogData.email, this.dialogData.password);
          }
          this.dialogRef.close();
        } else {
          this.alertService.show({
            title: $localize`Ваш аккаунт не активирован`,
            message: $localize`Проверьте вашу почту`,
            status: AlertStatus.Error,
          });
        }
      });
  }

  private startTimer(): void {
    this.isTimerActive.set(true);
    this.remainingTime.set('59');

    interval(1000)
      .pipe(
        take(59),
        tap(() => {
          this.remainingTime.set(
            `${+this.remainingTime() - 1}`.padStart(2, '0')
          );
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        complete: () => {
          this.isTimerActive.set(false);
        },
      });
  }
}
