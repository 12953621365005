import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ILoginResponse } from '@app/shared/models/interfaces/auth/login-response.interface';
import { ILogin } from '@app/shared/models/interfaces/auth/login.interface';
import { IRegistration } from '@app/shared/models/interfaces/auth/registration.interface';
import { TokenService } from '@app/shared/services/token/token.service';
import { environment } from '@env/environment';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private httpBE: HttpClient;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private activatedRoute: ActivatedRoute,
    private httpBackend: HttpBackend,
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {
    this.httpBE = new HttpClient(this.httpBackend);
  }

  public login$(loginData: ILogin): Observable<ILoginResponse> {
    return this.httpBE.post<ILoginResponse>(
      `${environment.apiUrl}/web/auth/login`,
      loginData,
      {
        headers: {
          'Accept-Language': this.locale,
        },
      }
    );
  }

  public logout$(): Observable<void> {
    return this.httpClient
      .post<void>(`${environment.apiUrl}/web/auth/logout`, null)
      .pipe(
        tap(() => {
          this.tokenService.deleteTokens();
        })
      );
  }

  public registration$(userData: IRegistration): Observable<void> {
    return this.httpBE.post<void>(
      `${environment.apiUrl}/web/users/register`,
      {
        ...userData,
        adName: this.activatedRoute.snapshot.queryParams['ad_name'] || null,
        adsetName:
          this.activatedRoute.snapshot.queryParams['adset_name'] || null,
        campaign:
          this.activatedRoute.snapshot.queryParams['campaign_name'] || null,
        domain: location.host || null,
        utmSource:
          this.activatedRoute.snapshot.queryParams['utm_source'] || null,
      },
      {
        headers: {
          'Accept-Language': this.locale,
        },
      }
    );
  }

  public resendActivationLetter$(email: string): Observable<void> {
    return this.httpBE.post<void>(
      `${environment.apiUrl}/web/users/message/resend`,
      {
        email,
      },
      {
        headers: {
          'Accept-Language': this.locale,
        },
      }
    );
  }
}
