import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function matchValidator(controlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let otherControl: UntypedFormControl;

    if (!control.parent) {
      return null;
    } else {
      otherControl = control.parent.get(controlName) as UntypedFormControl;
    }

    if (!control.value && otherControl.value) {
      return {
        required: true,
      };
    }

    if (!otherControl || !otherControl.value) {
      return null;
    }

    if (otherControl.value !== control.value) {
      return {
        match: true,
      };
    }

    return null;
  };
}
