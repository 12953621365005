<app-close-button class="modal" (click)="closeDialog()"></app-close-button>

<img
  alt="App logo"
  class="logo"
  [appColorThemeImage]="{
    light: 'assets/svg/logo-anatomy.svg',
    dark: 'assets/svg/logo-dark.svg',
  }" />

<span i18n class="t-heading-3 t-bold title">
  Войдите или зарегистрируйтесь бесплатно
</span>

<p i18n class="t-subtitle subtitle">
  Кредитная карта не требуется. Введите свой email
</p>

<form class="email-form" [formGroup]="authForm" (ngSubmit)="continueAuth()">
  <app-input
    i18n-label
    label="Электронная почта"
    type="email"
    name="email"
    [errorsMap]="{
      email: 'email',
      required: 'email',
    }"
    formControlName="email"></app-input>

  <button
    appButton
    class="t-button-1 submit"
    type="submit"
    i18n
    [disabled]="emailInCheck"
    [loading]="emailInCheck">
    Продолжить
  </button>
</form>
