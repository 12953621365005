@if (!(emptyControl && !inputInFocus)) {
  <span class="label">
    {{ label }}
    @if (markedAsRequired) {
      *
    }
  </span>
}

<div
  class="input-container"
  [ngClass]="{
    'input-container_opened': optionsOpened,
    'input-container_error': error,
  }">
  <input
    #input
    class="input t-heading-6"
    [formControl]="formControl"
    [placeholder]="emptyControl && !inputInFocus ? label : ''"
    (focus)="openOptionsList()"
    (focus)="setInputFocusStatus(true)"
    (blur)="setInputFocusStatus(false)"
    (blur)="blur($event)" />

  <svg-icon
    class="arrow"
    [class.arrow_up]="optionsOpened"
    key="dropdown-arrow"
    size="xl"></svg-icon>
</div>

@if (optionsOpened && shownOptions.length) {
  <div class="options-container">
    @for (option of shownOptions; track $index) {
      <div class="option t-heading-6" (click)="selectOption(option.name)">
        <ng-container
          *ngTemplateOutlet="itemRef; context: { $implicit: option }">
        </ng-container>
      </div>
    }
  </div>
}

<span class="error">{{ error }}</span>
