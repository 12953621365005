import { isPlatformServer } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '@app/shared/models/constants/tokens';
import { CookieKeys } from '@app/shared/models/enums/cookie-keys.enum';
import { ILoginResponse } from '@app/shared/models/interfaces/auth/login-response.interface';
import { IToken } from '@app/shared/models/interfaces/auth/token.interface';
import { CookieService } from '@app/shared/services/cookie/cookie.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private httpBE = new HttpClient(this.http);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(ACCESS_TOKEN) private accessToken: string,
    @Optional() @Inject(REFRESH_TOKEN) private refreshToken: string,
    private cookieService: CookieService,
    private http: HttpBackend
  ) {}

  public setToken(token: IToken): void {
    token.accessToken = token.accessToken.replace('Bearer ', '');
    token.refreshAccessToken = token.refreshAccessToken.replace('Bearer ', '');

    this.cookieService.setCookie(
      CookieKeys.RefreshToken,
      token.refreshAccessToken
    );
    this.cookieService.setCookie(CookieKeys.AccessToken, token.accessToken);
  }

  public getAccessToken(): string {
    if (isPlatformServer(this.platformId)) {
      return this.accessToken || '';
    }

    return this.cookieService.getCookie(CookieKeys.AccessToken);
  }

  public getRefreshToken(): string {
    if (isPlatformServer(this.platformId)) {
      return this.refreshToken || '';
    }

    return this.cookieService.getCookie(CookieKeys.RefreshToken);
  }

  public deleteTokens(): void {
    this.cookieService.deleteCookie(CookieKeys.RefreshToken);
    this.cookieService.deleteCookie(CookieKeys.AccessToken);
  }

  public refreshToken$(refreshToken: string): Observable<ILoginResponse> {
    const headers = refreshToken
      ? { 'Authorization-Refresh': `Bearer ${refreshToken}` }
      : {};

    return this.httpBE.post<ILoginResponse>(
      `${environment.apiUrl}/web/auth/refresh`,
      null,
      {
        headers,
      }
    );
  }
}
