import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { emailValidator } from '@app/shared/validators/email.validator';
import {
  checkUserEmailOnAuth,
  checkUserEmailReset,
} from '../../../store/user/user.actions';
import {
  selectEmailAuthCheck,
  selectEmailInCheck,
} from '../../../store/user/user.selectors';
import { InputComponent } from '../../controls/input/input.component';
import { ColorThemeImageDirective } from '../../directives/color-theme-image/color-theme-image.directive';
import { AccountVerificationComponent } from '../account-verification/account-verification.component';
import { BtnDirective } from '../btn/btn.directive';
import { CloseButtonComponent } from '../close-button/close-button.component';
import { LoginComponent } from '../login/login.component';
import { SignUpComponent } from '../sign-up/sign-up.component';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    AsyncPipe,
    BtnDirective,
    CloseButtonComponent,
    ColorThemeImageDirective,
    InputComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent implements OnInit, OnDestroy {
  public authForm = this.fb.group({
    email: ['', [Validators.required, emailValidator()]],
  });

  public emailInCheck = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private dialog: Dialog,
    private dialogRef: DialogRef<any, AuthComponent>,
    private fb: FormBuilder,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.watchEmailCheck();
    this.setContinueButtonSpinner();
  }

  public ngOnDestroy(): void {
    this.resetCheckState();
  }

  public continueAuth(): void {
    this.authForm.markAllAsTouched();

    if (this.authForm.valid) {
      this.store.dispatch(checkUserEmailOnAuth(this.authForm.getRawValue()));
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private watchEmailCheck(): void {
    this.store
      .select(selectEmailAuthCheck)
      .pipe(
        filter(check => check !== null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(check => {
        if (check.enabled && check.enabled) {
          this.dialog.open(LoginComponent, {
            data: this.authForm.getRawValue(),
          });
        } else if (!check.exists) {
          this.dialog.open(SignUpComponent, {
            data: this.authForm.getRawValue(),
          });
        } else if (check.exists && !check.enabled) {
          this.dialog.open(AccountVerificationComponent, {
            data: this.authForm.getRawValue(),
          });
        }

        this.closeDialog();
      });
  }

  private resetCheckState(): void {
    this.store.dispatch(checkUserEmailReset());
  }

  private setContinueButtonSpinner(): void {
    this.store
      .select(selectEmailInCheck)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(loadingStatus => {
        this.emailInCheck = loadingStatus;
        this.cdRef.markForCheck();
      });
  }
}
