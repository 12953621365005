import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { catchError, EMPTY, finalize } from 'rxjs';

import { AuthApiService } from '../../api/auth/auth-api.service';
import { AutocompleteComponent } from '../../controls/autocomplete/autocomplete.component';
import { CheckboxComponent } from '../../controls/checkbox/checkbox.component';
import { DropdownComponent } from '../../controls/dropdown/dropdown.component';
import { InputComponent } from '../../controls/input/input.component';
import { ROUTING_MAP } from '../../models/constants/routing-map';
import { userRoles } from '../../models/constants/user-roles';
import { AlertStatus } from '../../models/enums/alert-status.enum';
import { IRole } from '../../models/interfaces/auth/role.interface';
import { AlertService } from '../../services/alert.service';
import { CountriesService } from '../../services/countries/countries.service';
import {
  addFormValidators,
  FormValidators,
  removeFormValidators,
} from '../../utility/add-form-validators';
import { countryValidator } from '../../validators/country.validator';
import { emailValidator } from '../../validators/email.validator';
import { lengthValidator } from '../../validators/length.validator';
import { matchValidator } from '../../validators/match.validator';
import { AccountVerificationComponent } from '../account-verification/account-verification.component';
import { BtnDirective } from '../btn/btn.directive';
import { CloseButtonComponent } from '../close-button/close-button.component';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    AutocompleteComponent,
    BtnDirective,
    CheckboxComponent,
    CloseButtonComponent,
    DropdownComponent,
    InputComponent,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent implements OnInit {
  public inputMaxLength = 50;

  public passwordMaxLength = 30;

  public signUpForm: FormGroup;

  public roles: IRole[] = userRoles;

  public routingMap = ROUTING_MAP;

  public participateAsSelected = false;

  public isSigningUp = false;

  private formValidators: FormValidators = {
    email: [Validators.required, emailValidator()],
    password: [Validators.required, lengthValidator(6, this.passwordMaxLength)],
    passwordConfirm: [matchValidator('password')],
    firstName: [Validators.required],
    lastName: [Validators.required],
    country: [countryValidator(this.countriesService.fulfilledCountriesList)],
    participateAs: [Validators.required],
    policy: [Validators.requiredTrue],
  };

  constructor(
    @Inject(DIALOG_DATA) private dialogData: { email: string },
    public countriesService: CountriesService,
    private alertService: AlertService,
    private authService: AuthApiService,
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private dialog: Dialog,
    private dialogRef: DialogRef<any, SignUpComponent>,
    private fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.initSignUpForm();
    this.countriesService.setupLocalizedCountries();
  }

  public optionSelect(isLast: boolean): void {
    const field = this.signUpForm.get('participateAsDescription');
    this.participateAsSelected = isLast;
    field?.setValue(null);
    field?.clearValidators();
  }

  public openLogin(): void {
    this.dialog.open(LoginComponent);
    this.close();
  }

  public signUp(): void {
    this.signUpForm.markAllAsTouched();

    addFormValidators(this.signUpForm, this.formValidators);

    if (this.signUpForm.valid) {
      const formValue = this.signUpForm.getRawValue();
      this.isSigningUp = true;

      const countryCode = this.countriesService.getCountryCodeByCountryName(
        formValue.country
      );

      this.authService
        .registration$({
          ...formValue,
          email: formValue.email.toLowerCase().trim(),
          country: countryCode,
          specialty: '',
        })
        .pipe(
          catchError(error => {
            this.alertService.show({
              title: error.error?.title,
              message: error.error?.message,
              status: AlertStatus.Error,
            });

            return EMPTY;
          }),
          finalize(() => {
            this.isSigningUp = false;
            this.cdRef.markForCheck();
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => {
          this.dialog.open(AccountVerificationComponent, {
            data: {
              email: this.signUpForm.get('email').value,
              password: this.signUpForm.get('password').value,
            },
          });
          this.close();
        });
    }

    this.cdRef.markForCheck();
  }

  public close(): void {
    this.dialogRef.close();
  }

  private initSignUpForm(): void {
    this.signUpForm = this.fb.group({
      firstName: '',
      lastName: '',
      email: [this.dialogData?.email],
      password: '',
      passwordConfirm: '',
      country: '',
      participateAs: null,
      participateAsDescription: null,
      policy: false,
    });

    this.signUpForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        removeFormValidators(this.signUpForm);
      });
  }
}
